import axios from '@/utils/axios'


function findMessage(param, fun){
    axios.post('/api/sysuser/findMessage', param).then((data) => {
      fun(data)
    })
}


function checkState(param, fun){
  axios.post('/api/sysuser/checkState', param).then((data) => {
    fun(data)
  })
}



function deleteMessage(param, fun){
  axios.post('/api/sysuser/deleteMessage', param).then((data) => {
    fun(data)
  })
}







export { findMessage,checkState,deleteMessage}