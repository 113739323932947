import axios from '@/utils/axios'

//查询设备
function equipmentList(param, fun){
  console.log(param)
    axios.post('api/sysEquipment/equipmentDataList', param).then((data) => {
      fun(data)
    })
}

// function equipmentList(param, fun){
//   console.log(param)
//     axios.post('api/sysEquipment/equipmentDataList', param).then((data) => {
//       fun(data)
//     })
// }

//添加设备
function insertEquipment(param, fun){
  console.log(param)
    axios.post('api/sysEquipment/insertEquipment', param).then((data) => {
      fun(data)
    })
}

//更新设备
function updateEquipment(param, fun){
  console.log(param)
    axios.post('api/sysEquipment/updateEquipment', param).then((data) => {
      fun(data)
    })
}

export { equipmentList,insertEquipment,updateEquipment}