import React,{Component} from 'react'
import { Row, Col,Card,Divider,Form,Input,Button, Space, message } from 'antd';
import { connect } from 'react-redux'
import {findCompany,upload} from "./service"



class CompanyInfo extends Component {


    formRef = React.createRef();


    constructor() {
        super()
        this.state = {
            cardLoading:false,
            info:{}
         }

    }


    onFormFinish(e){
      console.log(e)
      const hide = message.loading('正在修改', 0);
      upload({
        contacts:e.linkMan,
        contactsPhone:e.linkPhone,
        id:this.props.userInfo.id
      },res=>{
          console.log(res)
          hide()
          if(res){
            message.success({content:res.message,key:"update"})
            findCompany({id:this.props.userInfo.id},res=>{
                if(res){
                    console.log(res)
                    this.setState({
                        info:res.data
                    },()=>{this.formRef.current.setFieldsValue({linkMan:res.data.contacts,linkPhone:res.data.contacts_phone})})
                }
            })

          }else{

          }
      })
    }
    componentDidMount(){
        console.log(this.props)
        this.setState({
            cardLoading:true
        })


        findCompany({id:this.props.userInfo.id},res=>{
            this.setState({
                cardLoading:false
            })
            if(res){
                console.log(res)
                this.setState({
                    info:res.data
                },()=>{this.formRef.current.setFieldsValue({linkMan:res.data.contacts,linkPhone:res.data.contacts_phone})})
            }
        })
    }
    init(){

    }
    render() {


        //form布局
        const formLayout={
            layout:'vertical',
            labelCol: { span: 8,offset:0 },
            wrapperCol: { span: 16 },
        }

        return (

        <Row gutter={16} >
            <Col span={8} >
               <Card title="账户信息" hoverable={true} loading={this.state.cardLoading}>
               <Divider orientation="left" >  企业名称   </Divider>
                 {this.state.info.company_name}
                <Divider orientation="left" >  社会信用代码   </Divider>
                {this.state.info.company_code}
                <Divider orientation="left" >  地址   </Divider>
                {this.state.info.company_addr}
                <Divider orientation="left" >  所属区域   </Divider>
                {this.state.info.parentName}
               </Card>
            </Col>
            <Col span={16}>
              <Card hoverable={true} title="联系人信息" loading={this.state.cardLoading}>
              <Form {...formLayout}  ref={this.formRef}    onFinish={this.onFormFinish.bind(this)}>
                <Form.Item label="联系人：" name="linkMan"    >
                  <Input  placeholder="请输入驾驶员名称"/>
                </Form.Item>
                <Form.Item label="联系方式：" name="linkPhone" >
                  <Input  placeholder="请输入驾驶员名称"/>
                </Form.Item>

                <Form.Item style={{textAlign:'center'}}>
                    <Space>
                      <Button type="primary" htmlType="submit">修改</Button>
                      <Button type="dashed">取消</Button>
                    </Space>
                </Form.Item>
              </Form>


              </Card>
            </Col>
        </Row>);
    }
}


const mapStateToProps = state=>state

const mapDispatchToProps=dispatch=>({})

export default connect(mapStateToProps,mapDispatchToProps)(CompanyInfo);

