import axios from '@/utils/axios'


//数据回显
function findCompany(param, fun){
    axios.post('/api/syscompany/findCompany', param).then((data) => {
      fun(data)
    })
}


//修改联系人信息
function upload(param, fun){
    axios.post('/api/syscompany/upload', param).then((data) => {
      fun(data)
    })
}







export { findCompany,upload}