import React from 'react';
// import logo from './logo.svg';
import { Provider } from 'react-redux';
import { hot } from 'react-hot-loader/root';
import Router from './router/index';
import store from '@/redux/store';
import './App.scss';
import '@/assets/scss/common.scss'
import { ConfigProvider } from "antd"//配置antd

import cn from "antd/es/locale/zh_CN"//中文格式
class App extends React.Component{
  render(){
    return (
      <ConfigProvider locale={cn}>
       <Provider store={store}>
        <Router />
       </Provider>
      </ConfigProvider>
    )
  }
}


export default hot(App);

