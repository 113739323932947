import React, { Component } from 'react'
import Particles from 'react-particles-js';//粒子效果
import "@/assets/scss/login.scss"
import {Form, Input,Button,message } from "antd"
import { UserOutlined , LockOutlined } from "@ant-design/icons"
import { setUserInfo } from '@/redux/actions/userInfo';
import { connect } from "react-redux"
import {login} from "./service"

export class Login extends Component {

    state= {clientHeight:document.documentElement.clientHeight||document.body.clientHeight, }

    formRef = React.createRef();

    constructor(props){
      super(props)
      this.onResize=this.onResize.bind(this)

   }
   componentDidMount(){
      //window.addEventListener("resize",this.onResize)
   }
   //组件销毁
   componentWillUnmount(){
      window.removeEventListener("resize",(res)=>{})
   }
   //展示注册弹出框
   routeRegister(){
    this.props.history.push("/register")

   }

   //系统监听窗口重置
	onResize() {
        console.log("onResize")
	   this.setState({ clientHeight: document.documentElement.clientHeight || document.body.clientHeight });
   }
   onFormFinish(value){
    console.log(value)
     let hide= message.loading({content:"正在登录...",key:"login"})
    login({
        companyCode:value.userName,
        password:value.password
    },(res)=>{
        console.log(res)
      if(res){

        message.success({content:res.message,key:"login"})
        localStorage.setItem('isLogin', '1');
        localStorage.setItem('userInfo', JSON.stringify(Object.assign({}, res.data, { role: { type: 1, name: res.data.companyName } })));
        this.props.setUserInfo(Object.assign({}, res.data, { role: { type: 1, name: res.data.companyName } }));

        setTimeout(()=>{
          this.props.history.push("/dashboard")
        },2000)

      }else{
        hide()
      }



    })



   }
    render() {
        return (
           <div className="container">
               <Particles height={this.state.clientHeight-6+'px'}
               params={

                {
  "particles": {
    "number": {
      "value": 160,
      "density": {
        "enable": true,
        "value_area": 800
      }
    },
    "color": {
      "value": "#ffffff"
    },
    "shape": {
      "type": "circle",
      "stroke": {
        "width": 0,
        "color": "#000000"
      },
      "polygon": {
        "nb_sides": 5
      },
      "image": {
        "src": "img/github.svg",
        "width": 100,
        "height": 100
      }
    },
    "opacity": {
      "value": 1,
      "random": true,
      "anim": {
        "enable": true,
        "speed": 1,
        "opacity_min": 0,
        "sync": false
      }
    },
    "size": {
      "value": 3,
      "random": true,
      "anim": {
        "enable": false,
        "speed": 4,
        "size_min": 0.3,
        "sync": false
      }
    },
    "line_linked": {
      "enable": false,
      "distance": 150,
      "color": "#ffffff",
      "opacity": 0.4,
      "width": 1
    },
    "move": {
      "enable": true,
      "speed": 1,
      "direction": "none",
      "random": true,
      "straight": false,
      "out_mode": "out",
      "bounce": false,
      "attract": {
        "enable": false,
        "rotateX": 600,
        "rotateY": 600
      }
    }
  },
  "interactivity": {
    "detect_on": "canvas",
    "events": {
      "onhover": {
        "enable": true,
        "mode": "bubble"
      },
      "onclick": {
        "enable": true,
        "mode": "repulse"
      },
      "resize": true
    },
    "modes": {
      "grab": {
        "distance": 400,
        "line_linked": {
          "opacity": 1
        }
      },
      "bubble": {
        "distance": 250,
        "size": 0,
        "duration": 2,
        "opacity": 0,
        "speed": 3
      },
      "repulse": {
        "distance": 400,
        "duration": 0.4
      },
      "push": {
        "particles_nb": 4
      },
      "remove": {
        "particles_nb": 2
      }
    }
  },
  "retina_detect": true
}} ></Particles>
               <div className="content">
                  <div className="title">智慧叉车系统</div>
                  {/* labelCol={{span:4}} */}
                   <Form name="login-form" size="medium"  ref={this.formRef}   className="login-form" onFinish={this.onFormFinish.bind(this)} >
                        <Form.Item  name="userName" rules={[{required:true,message:"请输入用户名"}]}>
                            <Input  placeholder="请输入企业社会统一信用代码证编号" prefix={ <UserOutlined  style={{ color: 'rgba(0,0,0,.25)' }} />} />
                        </Form.Item>
                        <Form.Item   name="password"  rules={[{required:true,message:"请输入密码"}]}>
                            <Input.Password  placeholder="请输入密码" prefix={ <LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} /> } />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" block  htmlType="submit">登录</Button>
                            <div onClick={this.routeRegister.bind(this)} style={{ color: '#999',paddingTop:'10px',textAlign:'right',cursor:"pointer" }}> 注册</div>
                        </Form.Item>
                   </Form>

               </div>

           </div>
        )
    }
}

// mapStateToProps：将state映射到组件的props中
const mapStateToProps = state => state;

// mapDispatchToProps：将dispatch映射到组件的props中
const mapDispatchToProps=dispatch=>({
    setUserInfo:data=>{
        dispatch(setUserInfo(data))
    }
})

export default connect(mapStateToProps,mapDispatchToProps)(Login)


