import React, { Component } from 'react'
import
{
  Skeleton,
  Table,
  Tooltip,
  Tag,
  Button,
  Space,
  Modal,
  message
} from 'antd';

import { findMessage,checkState,deleteMessage} from "./service"

import { connect } from 'react-redux'
import { ExclamationCircleOutlined} from "@ant-design/icons"


const { confirm } = Modal;

export class MaintenanceCheckRecord extends Component {

  //form表单
  formRef = React.createRef();
  //构造器
  constructor(props){
      super(props)
      this.state={
        loading:false,
        fold:true,
        tableData:[],
        pagination:{
            current:1,//当前页面
            pageSize: 10, //页面尺寸
            total:0,
            position:["bottomCenter"],
            showTotal:(total)=>`共 ${total} 条`
        },
        dialogVisible:false,
        detail:{},
        maintenanceTypeList:[]

      }
  }
    //	分页、排序、筛选变化时触发
    handleTableChange(pagination, filters, sorter, extra){

      if(extra.action==="paginate"){
         console.log("分页中")
         console.log(pagination)
         this.setState({
          pagination:{
              current:pagination.current,
              pageSize:pagination.pageSize,
              total: pagination.total,
              position:["bottomCenter"],
              showTotal:(total)=>`共 ${total} 条`
          }
         },()=>{
             console.log("修改成功")
             this.handleQueryInfo()
         })


      }

  }
  //叉车档案信息
  showDetail(h){
    console.log(h)
    this.setState({
        detail:h,
        dialogVisible:true
    })
  }
  //删除
  handleDelete(h){
    console.log(h)
    const that=this
    confirm({
      title:"温馨提示",
      content:"确定要删除此条消息吗？",
      icon: <ExclamationCircleOutlined style={{color:'red'}} />,
      onOk(){
          return new Promise((resolve,reject)=>{
            deleteMessage({id:h.id},res=>{
                  console.log(res)
                  if(res){
                      message.success(res.message)
                      resolve()
                      that.handleQueryInfo()
                  }
              })
          })
      },
      onCancel(){

      }
  })

  }
  //阅读
  handleRead(h){
    console.log(h)
    checkState({
      id:h.id
    },res=>{
      console.log(res)
      if(res){
        this.handleQueryInfo()
      }
    })
  }
  //关闭弹出框
  close(e){
   console.log("receive",e)
   this.setState({
    dialogVisible:false
   })
  }

   componentDidMount(){

       this.handleQueryInfo()
   }
       //表单提交
   onFormFinish(values){

    this.setState({
      pagination:{
          current:1,
          pageSize:10,
          total:0,
          position:["bottomCenter"],
          showTotal:(total)=>`共 ${total} 条`
      }
     },()=>{
         this.handleQueryInfo()
     })
    }
    //查询数据
    handleQueryInfo(){
      findMessage({
          companyId:this.props.userInfo.id,
          currentPage:this.state.pagination.current,
          pageSize:this.state.pagination.pageSize,
      },(res)=>{
          console.log(res)
          if(!res){
               return
          }
          this.setState({
              loading:false,
              tableData:res.data.list.map((item,index)=>{
                  item.key=index
                  return item
              }),
              pagination:{
                  current:this.state.pagination.current,
                  pageSize:this.state.pagination.pageSize,
                  total: res.data.total,
                  position:["bottomCenter"],
                  showTotal:(total)=>`共 ${total} 条`
              }
          })


      })
  }


  render() {
        //form布局
      //   const formLayout={
      //     layout:'inline',
      //     labelCol: { span: 8,offset:0 },
      //     wrapperCol: { span: 16 },
      // }



      const columns=[
        {
          title:'消息内容',
          align:"center",
          ellipsis: true,
          render(h) {

           const Content=()=>{
             if(h.state==='1'){
                 return <span style={{color:'gray'}}> {h.messageContent}</span>
             }else {
                return <span > {h.messageContent}</span>
             }
           }
           return  <Tooltip placement="topLeft" title={h.messageContent}> <Content></Content>  </Tooltip>
          },
        },{
          title:'状态',
          align:"center",
          render(h) {
            if(h.state==='1'){
             return <Tag color="red">{h.stateName}</Tag>
            }else{
              return <Tag color="blue">{h.stateName}</Tag>
            }


          },
        },
        {
          title:'创建时间',
          dataIndex:"createDate",
          align:"center",
        },
        {
          title:'操作',
          align:"center",
          render:(h)=>{
              return (
                <Space>
                 <Button type="link"  size="small"  onClick={()=>{this.handleRead(h)}} >已读</Button>
                 <Button type="link" danger size="small"  onClick={()=>{this.handleDelete(h)}} >删除</Button>
                </Space>
              )
          }
        }
      ]
        return (
            <div className="shadow-radius">
               {this.state.loading&&<Skeleton  active  paragraph={{ rows: 20 }}></Skeleton>}
               {this.state.loading||<div className="layout">

                    <Table columns={columns}     className="table-layout"  onChange={this.handleTableChange.bind(this)}  dataSource={this.state.tableData}  pagination={this.state.pagination}></Table>

               </div>}
            </div>
        )
    }
}



const mapStateToProps = state=>state

const mapDispatchToProps=dispatch=>({})

export default connect(mapStateToProps,mapDispatchToProps)(MaintenanceCheckRecord);
