import axios from '@/utils/axios'


function findSpotCheckList(param, fun){
    axios.post('/api/spotCheck/findSpotCheckList', param).then((data) => {
      fun(data)
    })
}



function findSpotCheckListById(param, fun){
    axios.post('/api/spotCheck/findSpotCheckListById', param).then((data) => {
      fun(data)
    })
}



function findType(param, fun){
  axios.post('/api/contract/findType', param).then((data) => {
    fun(data)
  })
}


function findList(param, fun){
  axios.post('/api/contract/findList', param).then((data) => {
    fun(data)
  })
}




export { findSpotCheckList,findSpotCheckListById,findType,findList}